header .header_search input[type="text"] {
    width:150px;
}
header{ padding:5px 7px}

header{ 
    background-color: #1572ae; 
    background-image: url('./components/img/ban_beg.png'),url('./components/img/ban_end.png');
    background-repeat: no-repeat, no-repeat;
    background-position: left top, right top;
    
    padding:10px;
    border-bottom:solid 1px #526267;
    height:50px;
    position:relative;
}
    header #logo{
        float:left;
    }
    header .header_search{
        position:absolute;
        color:#fff;
        font-size:20px;
        font-style: italic;
        font-family: Georgia, 'Times New Roman', Times, serif;
        bottom: 20px;
        right:20px
    }
    header .header_search input[type="text"] {
        width:170px;
        padding:9px 10px;
        border:solid 1px #004e9d;
        background:#004e9d;
        color:#fff;
        font-size:11px;
        border-radius:3px;
    }
    header .header_search input[type="submit"] {
        width:20px;
        height:20px;
        background:url(./components/img/search.png) no-repeat center;
        border:0px;
        position:absolute;
        right:7px;
        top:7px;
        cursor:pointer;
    }

    .Modal-module_modalCloseIcon__20QFz svg{
        width: 100px; height: 100px;
    }