@font-face { font-family: avenuex; src: url('./components/font/AvenueX-Regular.otf');}
@font-face { font-family: achone; src: url('./components/font/AchoneFont.ttf');}
@font-face { font-family: backrush; src: url('./components/font/Backrush\ 400.otf');}
@font-face { font-family: cameliya; src: url('./components/font/Cameliya.ttf');}
@font-face { font-family: cinderela; src: url('./components/font/Cinderela\ Personal\ Use\ Regular\ 400.ttf');}
@font-face { font-family: cocogoose; src: url('./components/font/Cocogoose-Condensed-Regular-trial.ttf');}
@font-face { font-family: flame; src: url('./components/font/Flame\ Fetish\ -\ TTF.ttf');}
@font-face { font-family: holden; src: url('./components/font/Holden-Medium-trial.ttf');}
@font-face { font-family: hysteria; src: url('./components/font/Hysteria\ 400.otf');}
@font-face { font-family: ominus; src: url('./components/font/Ominus\ 400.otf');}
@font-face { font-family: daunpenh; src: url('./components/font/DaunPenh.ttf');}

.app-color{color:#004F9D}

#activity_stats{height:38px;}
/* .widget_title{overflow: hidden;} */
#activity_stats label, .widget_title label{font-size: smaller;line-height: 31px; display: inline-block; margin-right: 10px;color:#004F9D;}
#activity_stats  ul.breadcrumbs li{display: inline-block; padding-right: 5px;color:#004F9D;}
#activity_stats  ul.breadcrumbs li::after{content: ' \00BB ';}
#activity_stats  ul.breadcrumbs li:last-child{color:#515b5e;font-size: smaller;}
#activity_stats  ul.breadcrumbs li:last-child::after{content: ' ';}
#activity_stats select, .widget_title select{border-color: rgb(118, 118, 118);}

.sized-ctrl{width:200px;}
.sc-1{width:100px;}
.sc-2{width:120px;}
.sc-3{width:140px;}
.sc-4{width:160px;}
.sc-5{width:180px;}
.sc-6{width:200px;}
.sc-7{width:400px;}


.text-sm{font-size: 14px;}
.text-lg{font-size: 18px;}
.text-xs{font-size: 10px;}
.text-md{font-size: 16px;}
.text-xl{font-size: 20px;}
.text-normal{font-size: 12px;}

a:hover{text-decoration: none; text-transform: none;}
a.logo-text{color:white; font-family:cinderela;font-size:20px; text-shadow: 0px 1px 1px #202324;}
.logo-icon{height:32px;display:inline-block;margin-top: -10px; margin-right: 5px;box-shadow: 0px 1px 1px #202324;padding: 1px}
.company-name{font-family: avenuex; text-align: right; color: white; font-size: 20px; padding-top: 5 px; font-style: italic; text-shadow: 0px 1px 1px #202324;}

.vcenter {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.widget{padding-bottom: 15px;}
.widget_title .btn-group-sm{height:31px;margin-top:8px;}
.widget_title .btn-group-sm span.iconsweet{margin-top: -16px;}
.widget_body{max-height: 75vh !important; overflow-x: scroll;}
span.iconsweet{color: inherit !important;}

.dataTables_wrapper .dataTable tbody td{padding: 5px 10px;}
.dataTables_wrapper .dataTable tbody tr{cursor: pointer;}
.dataTables_wrapper .dataTable tbody tr.selected{background-color: #b8d6f5ad}

hr{margin-top:5px;}

.imgcon{width:100px;height:120px;line-height:120px;cursor:pointer}
button.btn-outline-default{border: 1px solid #767676}

.preview li{margin: 10px;}
.preview li>span{display: inline-block;padding-left: 10px; color: #007bff}

.list-group{margin-top: 40px;font-size: small;}
.list-group-item{padding:5px;cursor: pointer;}
.list-group li.visited {background-color: rgb(177, 219, 255);}

.react-bootstrap-table table { table-layout: auto !important; }
.marks_ctl{width:50px;border:none;background-color:#d9edf7;}
.comment_ctl{width:100%;border:none;background-color:#d9edf7;}
.marks_hd{width:100%;border:none;background-color:#ffffff;text-align: center;font-size: 12px;font-weight: 600;}
.marks_tm{width:50px;border:none;background-color:#f2dede;text-align: center;font-size: 12px;font-weight: 600;}
.marks_tp{width:100%;border:none;background-color:#dff0d8;text-align: center;font-size: 12px;font-weight: 600;}

th{font-weight: 600;}

.sticky th:nth-child(2), .sticky td:nth-child(2)
{
  position:sticky;
  left:0px;
  background-color: #d9edf7;
}
.sticky td{vertical-align: middle;}

.nowrap {white-space: nowrap;}

#loader{
  position:fixed;
  z-index: 10000;
  top:0;
  left:0;
  width:100%;
  height:100%;
  /*display:none;*/
  background: url("./components/img/loader.gif") no-repeat center rgba(255,255,255,0.5);
}

.dash_head {font-weight: bold; font-size: medium;}

.bg-blue{background-color: #155e8b;}
.bg-red{background-color: #d81f27;}

.text-blue{color: #155e8b;}
.text-red{color: #d81f27;}

#toolCon{
    min-height: 500px; 
    background-color: rgba(5,123,254,.1);
    border-radius: 5px;
    padding: 6px 10px;
}
.toolTitle{
    line-height: 31px;
    font-weight: 600;
    color: #155e8b;
}
